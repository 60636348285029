var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"top-0 flex items-center justify-between w-full pl-10 shadow",class:_vm.getBarColor()},[_c('h3',{staticClass:"flex items-center py-3 text-2xl font-medium text-white"},[_c('NuxtLink',{attrs:{"to":"/"}},[_vm._v("\n          Redak\n      ")]),_vm._v(" "),(_vm.node_env === 'production')?_c('span',{staticClass:"ml-4 text-sm opacity-25"},[_vm._v("\n      "+_vm._s(_vm.version)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.node_env === 'acceptation')?_c('span',{staticClass:"ml-4 text-sm opacity-25"},[_vm._v("\n      Acceptation\n    ")]):_vm._e()],1),_vm._v(" "),_c('ul',{staticClass:"flex h-full"},[_c('li',{staticClass:"flex items-center px-3 py-2"},[_c('OnlineStatus',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var status = ref.status;
var isOnline = ref.isOnline;
return [(isOnline)?_c('div',[_c('NuxtLink',{staticClass:"text-white",attrs:{"to":"/help"}},[_vm._v("\n              Help ?\n            ")])],1):_c('div',[_c('BaseModel',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var openModel = ref.openModel;
return [_c('BaseButton',{on:{"click":openModel}},[_vm._v("\n                  Help ?\n                ")])]}},{key:"content",fn:function(){return [_c('p',[_vm._v("This functionality is only available when online.")]),_vm._v(" "),_c('small',[_vm._v("If you want to view the help page, please connect to the internet")])]},proxy:true}],null,true),model:{value:(_vm.helpModel),callback:function ($$v) {_vm.helpModel=$$v},expression:"helpModel"}})],1)]}}])})],1),_vm._v(" "),(_vm.user)?_c('li',{staticClass:"flex items-center px-10 py-3 text-white bg-blue-400"},[_c('BaseModel',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var openModel = ref.openModel;
return [_vm._v("\n          "+_vm._s(_vm.user.display_name)+" "),_c('span',{on:{"click":openModel}},[_vm._v(" (Logout)")])]}},{key:"content",fn:function(){return [_c('h2',{staticClass:"text-black"},[_vm._v("\n            Are you sure you want to logout\n          ")]),_vm._v(" "),_c('div',{staticClass:"flex mt-10"},[_c('BaseButton',{staticClass:"mr-5",attrs:{"color":"gray"},on:{"click":function($event){_vm.logoutModel = false}}},[_vm._v("\n              Cancel\n            ")]),_vm._v(" "),_c('BaseButton',{attrs:{"color":"red"},on:{"click":_vm.logout}},[_vm._v("\n              Logout\n            ")])],1)]},proxy:true}],null,false,606948144),model:{value:(_vm.logoutModel),callback:function ($$v) {_vm.logoutModel=$$v},expression:"logoutModel"}})],1):_vm._e(),_vm._v(" "),_c('li',[_c('OnlineStatus',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var status = ref.status;
var isOnline = ref.isOnline;
return [_c('div',{staticClass:"px-10 py-3 text-2xl font-medium text-white",class:{ 'bg-green-700': isOnline, 'bg-red-400': !isOnline }},[_vm._v("\n            "+_vm._s(status)+"\n          ")])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }