<template>
  <header :class="getBarColor()" class="top-0 flex items-center justify-between w-full pl-10 shadow">
    <h3 class="flex items-center py-3 text-2xl font-medium text-white">
        <NuxtLink to="/">
            Redak
        </NuxtLink>
      <span v-if="node_env === 'production'" class="ml-4 text-sm opacity-25">
        {{ version }}
      </span>
      <span v-if="node_env === 'acceptation'" class="ml-4 text-sm opacity-25">
        Acceptation
      </span>
    </h3>
    <ul class="flex h-full">
      <li class="flex items-center px-3 py-2">
        <OnlineStatus>
          <template #default="{ status, isOnline }">
            <div v-if="isOnline">
              <NuxtLink to="/help" class="text-white">
                Help ?
              </NuxtLink>
            </div>
            <div v-else>
              <BaseModel v-model="helpModel">
                <template #default="{ openModel }">
                  <BaseButton @click="openModel">
                    Help ?
                  </BaseButton>
                </template>

                <template #content>
                  <p>This functionality is only available when online.</p>
                  <small>If you want to view the help page, please connect to the internet</small>
                </template>
              </BaseModel>
            </div>
          </template>
        </OnlineStatus>
      </li>
      <li v-if="user" class="flex items-center px-10 py-3 text-white bg-blue-400">
        <BaseModel v-model="logoutModel">
          <template #default="{ openModel }">
            {{ user.display_name }} <span @click="openModel"> (Logout)</span>
          </template>

          <template #content>
            <h2 class="text-black">
              Are you sure you want to logout
            </h2>
            <div class="flex mt-10">
              <BaseButton color="gray" class="mr-5" @click="logoutModel = false">
                Cancel
              </BaseButton>
              <BaseButton color="red" @click="logout">
                Logout
              </BaseButton>
            </div>
          </template>
        </BaseModel>
      </li>
      <li>
        <OnlineStatus>
          <template #default="{ status, isOnline }">
            <div class="px-10 py-3 text-2xl font-medium text-white" :class="{ 'bg-green-700': isOnline, 'bg-red-400': !isOnline }">
              {{ status }}
            </div>
          </template>
        </OnlineStatus>
      </li>
    </ul>
  </header>
</template>

<script>
import { sync } from 'vuex-pathify'
import report from '@/database/models/report.js'

const serviceWorkerUpdatefoundRefreshDialog = require('service-worker-updatefound-refresh-dialog')

export default {
  data () {
    return {
      storageSize: {},
      node_env: process.env.NODE_ENV,
      version: process.env.VERSION,
      api_url: process.env.API_URL,
      worker: null,
      isUpdate: false,
      helpModel: false,
      logoutModel: false
    }
  },
  computed: {
    spaceLeft () {
      const rest = this.storageSize.quota - this.storageSize.usage
      return this.storageSize.quota / rest * 100
    },
    user: sync('auth/user'),
    report: sync('report')
  },
  mounted () {
    this.getStorageSize()
    this.checkForUpdate()
  },
  methods: {
    async checkForUpdate () {
      const reports = await report.index()

      if (this.report === null && reports.length === 0) {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.getRegistrations().then((registrations) => {
            for (const worker of registrations) {
              serviceWorkerUpdatefoundRefreshDialog.register(worker, {
                message: 'Update available for redak, click to update',
                onClick: (registration) => {
                  if (!registration.waiting) {
                    return
                  }
                  registration.waiting.postMessage('skipWaiting')
                  location.reload()
                }
              })
            }
          })
        }
      }
    },
    getBarColor () {
      const stages = {
        development: 'bg-red-800',
        production: 'bg-blue-800',
        acceptation: 'bg-orange-800'
      }

      return stages[this.node_env] || 'bg-blue-800'
    },
    async getStorageSize () {
      if ('storage' in navigator && 'estimate' in navigator.storage) {
        const data = await navigator.storage.estimate()
        this.storageSize = data
      }
    },
    logout () {
      this.user = undefined
    }
  }
}
</script>
